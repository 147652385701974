<template>
  <div style="max-width: 800px; margin: auto">
    <v-card max-width="90%" style="margin: auto" flat>
      <v-card-actions>
        <v-btn icon color="success" x-large><v-icon x-large class="f-s-100">mdi-check-circle</v-icon></v-btn>
      </v-card-actions>
      <v-card-actions>
        <v-btn icon color="success" x-large>Success</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>
<script>
export default {
}
</script>
